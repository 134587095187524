<template>
  <v-select
    v-bind="$attrs"
    v-on="$listeners"
    color="tertiary"
    :value="value"
    :items="$helpers.fromEnumToArray(IMPORTANCE)"
    :item-text="item => $t(`global.importance_enum.${item.text}`)"
    :label="$t('global.importance')"
    :menu-props="{ offsetY: true, overflowY: true }"
    @input="$emit('input', value)"
  />
</template>

<script>
  /**
   * @author Ali Hüseyin ŞİRELİ
   * @email ali.sireli@globme.com.tr
   * @create date 2020-12-31 15:29:33
   * @modify date 2020-12-31 15:29:33
   * @desc [description]
   */
  import { IMPORTANCE } from "@/helpers/enums";
  export default {
    name: "ImportancePicker",
    inheritAttrs: false,
    props: {
      value: String
    },
    data: vm => ({
      IMPORTANCE
    })
  };
</script>

<style></style>
